<template lang="pug">
  transition(:name='transition')
    b-alert.wc-icon-alert(
      v-if='active'
      :variant='variant'
      key='icon-alert'
      show)
      .row
        .col-1.alert-icon-col.d-flex.align-items-center
          i.fa-fw.fa-2x(:class='icon')
        .col
          b.d-block(v-if='titleComputed')
            | {{ titleComputed }}
          span(v-if='detailComputed')
            | {{ detailComputed }}
</template>

<script>
export default {
  name: 'wc-icon-alert',
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    transition: {
      type: String,
      default: 'fade',
    },
    variant: {
      type: String,
      default: 'wcsecondary',
    },
    icon: {
      type: String,
      default: 'fal fa-hourglass-half',
    },
    title: String,
    detail: String,
  },
  computed: {
    titleComputed() {
      return this.title || this.$t('notices.resource.not_implemented.danger.title')
    },
    detailComputed() {
      return this.detail || this.$t('notices.resource.not_implemented.danger.detail')
    },
  },
}
</script>

<style lang="scss">
.wc-icon-alert .alert-icon-col {
  min-width: 50px;
  max-width: 50px;
  padding-right: 0;
}
</style>
