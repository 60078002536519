<template lang="pug">
  b-card.manager-accounts-profile-form.card-white.card-no-gutters.shadow(no-body)
    wc-card-tabs(:tabs='tabs')
    b-form.form-wc.form-accounts-profile-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            b-form-group#form-roles-global(
              :label="$t('role.global')"
              label-for='roles-global'
              label-cols-md='2'
              label-align-md='right')
              b-form-checkbox-group#roles-global(
                name='roles_global'
                v-model='rolesGlobalSelected'
                size='md'
                button-variant='primary'
                buttons)
                b-form-checkbox.shadow-none(
                  v-for='(option, index) in rolesGlobalOptions'
                  :key='index'
                  :value='option.value')
                  | {{ option.text }}

            b-row
              b-col(offset-md='2')
                wc-icon-alert(
                  icon='fal fa-exclamation-triangle'
                  variant='warning'
                  :title="$t('role.alerts.global.title')"
                  :detail="$t('role.alerts.global.detail')")

      b-card-footer.border-0.bg-white
        wc-forms-buttons#form-roles-buttons(:disabled='formDisable || $v.form.data.$invalid')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import Tabs from '@views/container/manager/accounts/shared/tabs'
import WcIconAlert from '@components/shared/WcIconAlert'
import WcFormsButtons from '@components/shared/forms/WcFormsButtons'
import apiRoles from '@services/api/manager/accounts/roles'
import { Form, mapSelects } from '@common/form'

export default {
  name: 'manager-accounts-roles-form',
  mixins: [Tabs, Form],
  components: {
    WcIconAlert,
    WcFormsButtons,
  },
  computed: {
    ...mapSelects([
      {
        name: 'rolesGlobal',
        default: 'attributes.roles_global',
        attribute: 'attributes.roles_global',
        collection: {
          data: 'roles_global.data',
          key: 'id',
          value: 'attributes.name',
          options: { key_label: 'text', raw_ids: true },
        },
      },
    ]),
    apiParams() {
      return {
        get: [{ account_id: this.account_id }],
        update: [
          { account_id: this.account_id },
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
      }
    },
  },
  methods: {
    apiCallback(callback) {
      if (callback === 'submit-success') this.$parent.$emit('roles-changed')
    },
  },
  validations() {
    return {
      form: {
        data: {
          attributes: {
            roles_global: {},
          },
        },
      },
    }
  },
  data() {
    return {
      apiBase: apiRoles,
      apiModel: 'roles',
      account_id: this.$route.params.account_id,
    }
  },
}
</script>
